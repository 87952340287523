<template>
    <div class="mt-2">
      <div class="hidden sm:block">
        <div class="w-full mb-8">
        </div> 
        <div class="share-wrapper">     
          <share-it icons dark :targets="['facebook']" :shareConfig="iconConfig"/>
          <share-it icons dark :targets="['whatsapp']" :shareConfig="iconConfig"/>
          <share-it icons dark :targets="['twitter']" :shareConfig="iconConfig"/>
          <share-it icons dark :targets="['linkedin']" :shareConfig="iconConfig"/>
          <ShareEmail :url="resolveURL" :title="getTitle" :description="getDescription"/>
          <CopyToClipboard @clicked="copy"/>
        </div>
      </div>
        <!-- Mobile -->

      <div class="sm:hidden h-auto -mb-6 -mt-6">
        <div class="w-auto h-auto">
        </div> 
        <div class="share-wrapper">   
          <share-it icons dark  :targets="['facebook']" :shareConfig="iconConfigMobile"/>
          <share-it icons dark :targets="['whatsapp']" :shareConfig="iconConfigMobile"/>
          <share-it icons dark :targets="['twitter']" :shareConfig="iconConfigMobile"/>
          <share-it icons dark :targets="['linkedin']" :shareConfig="iconConfigMobile"/>
          <ShareEmail Myheight="1.33em" :url="resolveURL" :title="getTitle" :description="getDescription"/>
          <CopyToClipboard @clicked="copy"/>
        </div>
      </div>

        <!-- End Mobile -->
    </div>
</template>
<style scoped>
.share-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.share-wrapper > * {
  width: 16.6%;
}
.svg-inline--fa{
  color: black !important;
}
.share-wrapper *.share-icon {
  margin: 0!important;
}
.vue-share-it-wrapper{
  justify-content: center;
}
</style>
<script>
  import ShareEmail from '@/components/sharing/SpecificButtons/ShareEmail'
  import CopyToClipboard from '@/components/sharing/SpecificButtons/CopyToClipboard'
  // https://thisdotname.github.io/vue-share-it/


  export default {
    name: 'SocialNetworksPicker',
    components: {
      ShareEmail,
      CopyToClipboard
    },
    props:{
        title:{
            String,
            default: ''
        },
        description:{
            String,
            default: ''
        },
        quote:{
            String,
            default: ''
        },
        hashtags:{
            String,
            default: ''
        }
    },
    data(){
      return{
        iconConfig:{
          twitter: {
            dark: true,
            size: "2x",
            color: "#BE5E3B",
            round: true,
          },
          linkedin: {
            dark: true,
            size: "2x",
            color: "#BE5E3B",
            round: true,
          },
          facebook: {
            dark: true,
            size: "2x",
            color: "#BE5E3B",
            round: true,
          },
          whatsapp: {
            dark: true,
            size: "2x",
            color: "#BE5E3B",
            round: true,
          },
        },
        iconConfigMobile:{
          twitter: {
            dark: true,
            size: "md",
            color: "#BE5E3B",
            round: true,
          },
          linkedin: {
            dark: true,
            size: "md",
            color: "#BE5E3B",
            round: true,
          },
          facebook: {
            dark: true,
            size: "md",
            color: "#BE5E3B",
            round: true,
          },
          whatsapp: {
            dark: true,
            size: "md",
            color: "#BE5E3B",
            round: true,
          },
        }
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
       show() {
        this.$emit('show');
      },
      async copy() {
        await navigator.clipboard.writeText(window.location.href);
      }
      
    },
    computed:{
      resolveURL(){
        return window.location.href
      },
      getTitle(){
        return this.title
      },
      getDescription(){
        return this.description
      },
      getQuote(){
        return this.quote
      },
      getHashTags(){
        return this.hashtags
      }
    }
  };
</script>