<template>
    <transition name="modal-fade">
        <div class="modal-backdrop fixed inset-0 bg-black bg-opacity-50	flex justify-center items-center z-40">
            <div class="bg-white smallModal rounded-lg w-10/12 lg:w-1/3 h-auto overflow-y-none">
            <div class="thankyouModal">
              <div class="relative h-full w-full">
                <button class="close absolute right-4 top-4 z-40 h-auto" @click="close">
                    <inline-svg class="transform origin-center rotate-45 w-4 h-4 md:w-2 md:h-2 md:w-2 md:h-2 lg:w-5 lg:h-5" fill="black"
                      src="/svg/plus.svg" />
                  </button>
                <img src="/img/cover/cover3.jpg" class="h-full w-full object-cover thankyoumodal-img">
                <inline-svg width="5rem" height="auto" style="filter:invert(1);" class="hidden md:block absolute left-4 bottom-4 z-40 h-auto" src="/svg/logos/logo_agency_white.svg" />
                <inline-svg width="2rem" height="auto" style="filter:invert(1);" class="md:hidden absolute left-4 bottom-4 z-40 h-auto" src="/svg/logos/logo_agency_white.svg" />
              </div>
              <p class="font-blackMango text-lg text-center w-9/12 w-auto title">{{$t('SocialModal.thanks')}}</p>
              <p class="font-euclid text-md font-medium w-full md:w-auto text-center md:font-normal subtitle">{{$t('SocialModal.choose')}}</p>
              <div class="px-6 py-4">
                <slot name="body">
                  This is the default body!
                </slot>
              </div>
              <button @click="close"
                class="mt-3 mb-6 bg-black  hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white py-2 md:py-5 px-4 md:px-10 md:p-16">
                <span>{{ $t('SocialModal.back') }}</span>
            </button>
            </div>
            </div>
        </div>
        
    </transition>
</template>
<style scoped>
  .thankyouModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .smallModal{
    border-radius:23px;
  }
  .thankyoumodal-img{
    max-height: clamp(100px, 30vh, 400px);
    display: block;
    border-top-right-radius:23px;
    border-top-left-radius:23px;
  }
  @media (min-width:720px){
    .title{
      font-weight: bold;
      margin-top: 3rem;
    }
    p.subtitle{
      margin-top: .5rem;
      margin-bottom: 1rem;
    }
    span.subtitle{
      margin-bottom: 2rem;
      color: var(--color-gray);
    }
  }
  @media (max-width:719px){
    .title{
      font-weight: bold;
      margin-top: 1rem;
    }
    p.subtitle{
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
    span.subtitle{
      color: var(--color-gray);
    }
  }
  
  .paper-icon{
    width: 4rem;
    height: 4rem;
    fill:var(--color-bronze)
  }
</style>
<script>
  export default {
    name: 'SmallModal',
    methods: {
      close() {
        this.$emit('close');
      },
       show() {
        this.$emit('show');
      },
    },
  };
</script>