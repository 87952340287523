<template>
    <div class="vue-share-it-wrapper" @click="click" content="Copied to clipboard" v-tippy="{trigger : 'click', placement : 'bottom'}">
        <a class="share-icon icon-border-round share-icon-clip">
            <div class="hidden sm:block">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#BE5E3B" class="bi bi-link svg-inline--fa fa-w-14 fa-3x share-clipboard-svg" viewBox="0 0 18 18">
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                </svg>
            </div>

            <div class="sm:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#BE5E3B" class="bi bi-link svg-inline--fa fa-w-7 fa-3x" viewBox="-0 0 18 18">
                    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                </svg>
            </div>
        </a>
    </div>
</template>
<style scoped>
.share-clipboard-svg{
  width: 1em;
}
.share-icon-clip {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
<script>
export default {
    name: 'CopyToClipboard',
    data(){
        return{
            fontBold: false
        }
    },
    methods:{
        click(){
            this.fontBold = true
            this.$emit('clicked')
        }
    }
}
</script>
