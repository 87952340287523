var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.propertiesToDisplay[0] != null)?_c('div',{staticClass:"w-full bg-gray h-screen-50"},[(_vm.items.length > 0)?_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"100%","margin-left":"auto","margin-right":"auto"},attrs:{"id":"gMap","center":_vm.centerFirstProperty,"zoom":_vm.computeZoomValue,"options":_vm.getOptions,"map-type-id":"roadmap"}},[_vm._l((_vm.items),function(m,i){return _c('GmapCircle',{key:'FirstCirclePos: ' + i,attrs:{"center":m.position,"radius":parseInt(m.radius)*10,"visible":true,"options":_vm.selectedMark==m.id ? 
                  {
                    strokeColor: '#000000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#000000',
                    fillOpacity:0.35
                  } : 
                  { 
                    strokeColor: '#BE5E3B',
                    strokeOpacity: 0.8,
                    strokeWeight: 0,
                    fillColor: '#BE5E3B',
                    fillOpacity:0.35
                  }},on:{"click":function($event){return _vm.showTooltip(m)}}})}),(_vm.InformationWindowTemplateData.showInfoTemplate)?_c('GmapInfoWindow',{attrs:{"options":{maxWidth: 400},"position":_vm.infoWindow.position,"opened":_vm.infoWindow.open},on:{"closeclick":function($event){_vm.infoWindow.open=false}}},[_c('div',{staticClass:"overflow-hidden mt-2 flex flex-col "},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute left-2 bottom-2 md:left-3 md:bottom-3 z-10 flex justify-center items-center rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-1 md:px-4 glass-effect glass-fallback"},[_c('p',{staticClass:"text-darkgray text-base font-semibold"},[_vm._v(_vm._s(_vm.$currency(_vm.InformationWindowTemplateData.selectedMarkerPrice)))])]),_c('div',{staticClass:"overflow-hidden listing-card rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent lg:rounded-2xl w-full"},[_c('img',{staticClass:"lg:rounded-xl rounded-lg cursor-pointer object-center w-full h-full object-cover hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform",attrs:{"src":this.$mediaFile("properties", _vm.InformationWindowTemplateData.selectedMarkerID , 
              _vm.InformationWindowTemplateData.selectedMarkerCover),"lazy-src":this.$mediaFile("properties", _vm.InformationWindowTemplateData.selectedMarkerID , 
              _vm.InformationWindowTemplateData.selectedMarkerCover)},on:{"click":function($event){return _vm.generateLink(_vm.InformationWindowTemplateData.selectedMarkerID)}}})])]),_c('div',{staticClass:"flex space-x-1 md:space-x-2 lg:space-x-3"},[_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.bedrooms,"img":"/svg/houseDetails/rooms.svg","color":_vm.color}}),_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.bathrooms,"img":"/svg/houseDetails/baths.svg","color":_vm.color}}),_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.parking,"img":"/svg/houseDetails/garage.svg","color":_vm.color}}),_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.area,"img":"/svg/houseDetails/area.svg","color":_vm.color}})],1)])]):_vm._e()],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }