<template>
    <div class="menu-top fixed top-0 md:top-16 leading-none max-w-8xl w-11/12 inset-x-0 mx-auto flex flex justify-between  text-baselg font-blackMango font-semibold trackind-widest z-30">
        <div class="hidden lg:grid">
            <!-- glass-effect glass-effect-strong -->
            <button class="flex items-center py-1 px-3 md:p-3 lg:p-4 rounded-lg glass-effect glass-effect-white glass-fallback back-button" @click="$router.go(-1)">
                <inline-svg class="transform origin-center rotate-180 back-button-icon" width="24px" height="auto"
                        src="/svg/arrow/arrowBall.svg" /> {{ $t('buttons.back') }}</button>
        </div>

        <div class="block lg:hidden" @click="$router.go(-1)">
            <!-- glass-effect glass-effect-strong -->
                <inline-svg class="transform origin-center rotate-180 back-button-icon" width="24px" height="auto"
                        src="/svg/arrow/arrowBall.svg" />
        </div>


        <div class="flex items-center rounded-lg space-x-2 md:space-x-4 px-3 md:p-3 lg:p-4 glass-effect glass-effect-white glass-fallback">
            <button @click="show()">{{ $t('Fixed_Menu.Book_Visit') }}</button>
            <button @click="print">{{ $t('Fixed_Menu.Print') }}</button>
            <button @click="shareContent" id="sharingButton">{{ $t('Fixed_Menu.Share') }}</button>
        </div>
        <!-- Modal Social Network Sharing -->
        <Modal v-show="isSocialMediaVisible" @close="closeSocialNetworkModal">
            <template v-slot:body>
               <SocialNetworksPicker 
                    :title="$processLanguage(computeForm.i18n.title)"
                    :description="$processLanguage(computeForm.i18n.description)"     
                />
            </template>
        </Modal>
        <!-- end:: Modal Social Network Sharing -->
    </div>
</template>
<style scoped>
.back-button{
    display: flex;
    align-items: center;
}
.back-button-icon{
  margin-right: 10px;
}
@media (max-width:960px){
    .menu-top{
        margin-top:0.75rem;
    }
}
</style>
<script>
import SocialNetworksPicker from '@/components/sharing/SocialNetworksPicker'
import Modal from '@/components/modal/Modal.vue';

export default {
        name: "FixedMenu",
        components: { SocialNetworksPicker, Modal
        },
        props: {
            form: null
        },
        data: function () {
            return {
                isSocialMediaVisible: false,
                sharing: false
            }
        },
        methods: {
            show(item) {
                this.$emit('show',item);
            },
            shareContent(){
                let bounds = document.querySelector("#sharingButton").getBoundingClientRect()
                this.$emit('share', bounds);
            },
            print(){
                this.$emit('print');
            },
            closeContactModal() {
            this.isModalContactVisible = false;
            },
            shareSocialMedia(){
                this.isSocialMediaVisible = true;
            },
            closeSocialNetworkModal(){
                this.isSocialMediaVisible = false;
            },
        },
        computed:{
            computeForm(){
                return this.form
            }
        }
    }
</script>