<template>
    <div class="w-full bg-gray h-screen-50" v-if="propertiesToDisplay[0] != null">
      
      <GmapMap v-if="items.length > 0"
          id="gMap"
          :center="centerFirstProperty"
          ref="map"
          :zoom="computeZoomValue"
          :options="getOptions"
          map-type-id="roadmap"
          style="width: 100%; height: 100%; margin-left: auto; margin-right: auto;grayscale(100%); ">          
          
          <GmapCircle
            v-for="(m, i) in items"
            :key="'FirstCirclePos: ' + i"
            :center="m.position"
            :radius="parseInt(m.radius)*10"
            :visible="true"
            :options="selectedMark==m.id ? 
                      {
                        strokeColor: '#000000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#000000',
                        fillOpacity:0.35
                      } : 
                      { 
                        strokeColor: '#BE5E3B',
                        strokeOpacity: 0.8,
                        strokeWeight: 0,
                        fillColor: '#BE5E3B',
                        fillOpacity:0.35
                      }"
            @click="showTooltip(m)"
          />
          <!-- <GmapCircle
            v-for="(m, i) in items"
            :key="'SecondCirclePos: ' + i"
            :center="m.position"
            :radius="parseInt(m.radius)/6"
            :visible="true"
            :options="selectedMark==m.id ? 
                     {
                        strokeColor: '#000000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#000000',
                        fillOpacity:0.35
                      } : 
                      { 
                        strokeColor: '#BE5E3B',
                        strokeOpacity: 0.8,
                        strokeWeight: 0,
                        fillColor: '#BE5E3B',
                        fillOpacity:1
                      }"
            @click="showTooltip(m)"
          />-->
          <GmapInfoWindow
          v-if="InformationWindowTemplateData.showInfoTemplate"
          :options="{maxWidth: 400}"
          :position="infoWindow.position"
          :opened="infoWindow.open"
          @closeclick="infoWindow.open=false">
          <div class="overflow-hidden mt-2 flex flex-col ">
               <div class="relative">
                 <div class="absolute left-2 bottom-2 md:left-3 md:bottom-3 z-10 flex justify-center items-center rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-1 md:px-4 glass-effect glass-fallback">
                  <p class="text-darkgray text-base font-semibold">{{ $currency(InformationWindowTemplateData.selectedMarkerPrice) }}</p>
                </div>
                <div class='overflow-hidden listing-card rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent lg:rounded-2xl w-full'>
               <img 
                class='lg:rounded-xl rounded-lg cursor-pointer object-center w-full h-full object-cover hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform'
                @click="generateLink(InformationWindowTemplateData.selectedMarkerID)"
                :src='this.$mediaFile("properties", InformationWindowTemplateData.selectedMarkerID , 
                  InformationWindowTemplateData.selectedMarkerCover)'
                :lazy-src='this.$mediaFile("properties", InformationWindowTemplateData.selectedMarkerID , 
                  InformationWindowTemplateData.selectedMarkerCover)'>
               </div>
             </div>
             <div class="flex space-x-1 md:space-x-2 lg:space-x-3">
              <PropertyDetailsBig homepage :number='InformationWindowTemplateData.bedrooms' img="/svg/houseDetails/rooms.svg" :color="color" />
              <PropertyDetailsBig homepage :number='InformationWindowTemplateData.bathrooms' img="/svg/houseDetails/baths.svg" :color="color" />
              <PropertyDetailsBig homepage :number='InformationWindowTemplateData.parking' img="/svg/houseDetails/garage.svg" :color="color" />
              <PropertyDetailsBig homepage :number='InformationWindowTemplateData.area' img="/svg/houseDetails/area.svg" :color="color" />
            </div>
          </div>
          </GmapInfoWindow>
      </GmapMap>
    </div>
</template>

<style>
.gm-style-iw > button {
  display: none !important;
}
.gm-svpc{
  display: none !important;
}
</style>
<script>
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";

export default {
  name: "ListingMap",
  components: {
    PropertyDetailsBig
  },
  props: ['listingType', 'propertiesToDisplay', 'hasTooltip'],
  data() {
    return {
      center:  {lat:0, lng:0},
      markers: [],
      selectedMark: -1,
      markerChild: {
        lat: 0,
        lng: 0,
        radius: 25
      },
      newMarkers: [],
      showSaver: false,
      showRemover: false,
      toDeleteMarkers: [],
      places: [],
      reloadDiv: 0,
      currentPlace: null,
      block: 'location',
      infoWinOpen: false,
      infoWindow: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        },
      InformationWindowTemplateData: {
          selectedMarkerID: 0,
          selectedMarkerCover: '',
          selectedMarkerPrice: 0,
          showInfoTemplate: false,
          bathrooms: 0,
          bedrooms: 0,
          parking: 0,
          area: 0
      },    
      showMap: true  
    };
  },
    computed: {
        computeZoomValue(){
          let radiusValue = (this.propertiesToDisplay[0][0]).position.radius / 100
          if(radiusValue < 5)
            radiusValue += 9
          
          if(radiusValue < 10 && radiusValue >=5)
            radiusValue += 4

          if((this.propertiesToDisplay[0][0]).position.radius > 1400)
            radiusValue = 11

          if((this.propertiesToDisplay[0][0]).position.radius < 100)
            radiusValue = 14
            
          return radiusValue
        },
        centerFirstProperty(){    
          return (this.propertiesToDisplay[0][0]).position
        },
        items() {
          return this.propertiesToDisplay[0]
        },
        getLocation(){
          return this.propertiesToDisplay[1]
        },
        getOptions(){
          let options = {
            clickableIcons: false,
            mapTypeControl: false, 
            fullscreenControl: false,
            scrollwheel: false,
            styles: [
              {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#dadada"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
              },
              {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              }
            ]
          }


      return options
        }
    },
  methods: {
    // https://www.aspsnippets.com/Articles/Google-Maps-API-V3-Print-Google-Maps-with-Markers-using-JavaScript.aspx
    getGoogleMap(){
        //URL of Google Static Maps.
        var staticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";
 
        //Set the Google Map Center.
        staticMapUrl += "?center=" + this.centerFirstProperty.lat + "," + this.centerFirstProperty.lng;
 
        //Set the Google Map Size.
        staticMapUrl += "&size=220x350";
 
        //Set the Google Map Zoom.
        staticMapUrl += "&zoom=" + this.computeZoomValue;
 
        //Set the Google Map Type.
        staticMapUrl += "&maptype=roadmap";
 
        //Loop and add Markers.
        for (var i = 0; i < this.items.length; i++) {
            staticMapUrl += "&markers=color:red|" + this.items[i].position.lat + "," + this.items[i].position.lng;
        }

        //Display the Image of Google Map.
        var imgMap = document.getElementById("gMap");
        imgMap.src = staticMapUrl;
        imgMap.style.display = "block";

        return imgMap        
    },
    generateLink(id){
        this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
    },
    showTooltip(m){      
      if(!this.hasTooltip)
        return

      this.infoWindow.position = m.position
      this.infoWindow.open = true
      this.propertiesToDisplay[0].forEach(p => {     
        if(p.property_id == m.property_id){
          this.InformationWindowTemplateData.selectedMarkerCover = p.p_obj.cover
          this.InformationWindowTemplateData.selectedMarkerID = p.p_obj.property_id
          this.InformationWindowTemplateData.selectedMarkerPrice = this.$currency(p.p_obj.price)
          this.InformationWindowTemplateData.parking = p.p_obj.parking
          this.InformationWindowTemplateData.area = p.p_obj.gross_area
          this.InformationWindowTemplateData.bathrooms = p.p_obj.bathrooms
          this.InformationWindowTemplateData.bedrooms = p.p_obj.bedrooms
        }
        this.InformationWindowTemplateData.showInfoTemplate = true
      })
    }
  }
};
</script>

 
