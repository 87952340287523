<template>
    <div class="w-full h-full grid grid-cols-12">
        <!-- begin:: navigation arrow left -->
        <div class="col-span-1 h-full flex items-center justify-center">
            <button class="relative w-full h-full" @click="toPrevious()">
            <inline-svg class="absolute inset-0 m-auto transform origin-center rotate-180 w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="black"
                src="/svg/arrow/arrow.svg" />
        </button>
        </div>
        <!-- end:: navigation arrow left -->
        <!-- begin:: swiper -->
        <div class="col-span-10 h-full flex items-center justify-center">
        <swiper :options="swiperOption" @slideChange="changeNavigation" class="swiper "
            ref="swiperProduct">
                    
            <!-- Youtube video at slider -->
            <swiper-slide class="h-full flex items-center justify-center" v-if="hasVideo">
                <youtube class="object-contain max-h-screen" id="firstImage"  :video-id="youtubeVideoComputed"></youtube>
            </swiper-slide>
            <!-- end youtube video -->

            <swiper-slide class="h-full flex items-center justify-center" :key="i"
                v-for="(item, i) in gallery">
                     <img class="object-contain max-h-screen" 
                        :id="i == 0 && !hasVideo ? 'firstImage' : ''" 
                        :src="$mediaFile(imageLocation, item.property_id , item.file)"
                        :lazy-src="$mediaFile(imageLocation, item.property_id , item.file)"
                         alt="project"> 
            </swiper-slide>
        </swiper>
        </div>
        <!-- end:: swiper -->
        <!-- begin:: navigation arrow right -->
        <div class="col-span-1 h-full flex items-center justify-center">
            <button class="relative w-full h-full" @click="toNext()">
            <inline-svg class="absolute inset-0 m-auto w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="black" src="/svg/arrow/arrow.svg" />
        </button>
        </div>
    
        <!-- begin:: numeration -->
         <NavigationIndex class="mt-2 md:mt-3 lg:mt-6 col-span-12 flex w-100" :currentNumber="this.index" :totalNumber="this.length" color="bronze" />
         <!-- end:: numeration -->

    </div>
</template>
<style scoped>
.max-h-screen{
    max-height: 60vh !important;
}
.swiper-slide{
    margin: auto;
}
</style>
<script>
   import {
       Swiper,
       SwiperSlide
   } from 'vue-awesome-swiper'
   import 'swiper/swiper-bundle.css'
       import NavigationIndex from "@/components/navigationIndex/NavigationIndex.vue";
    
    import { getIdFromURL } from 'vue-youtube-embed'


   export default {
       name: "ImageSlider",
       components: {
           Swiper,
           SwiperSlide,
           NavigationIndex
       },
       props: {
           gallery: Array,
           starterImage: Number,
           youtubeVideo: {
               type: String,
               default: ''
           },
           isModalVisible: Boolean,
           imageLocation: String
       },

       data() {
           return {
               index: null,
               length: null,
               slidesPerView: 1,
               swiperOption: {
                   loop: false,
               },
               videoAdded: false
           }
       },
       mounted() {
           // Avoid swiper error if undefined
           if(this.$refs.swiperProduct == undefined)
            return

           this.index = (this.$refs.swiperProduct.$swiper.activeIndex) +1;
           this.length = this.gallery.length;
           this.toSlide(this.starterImage)
       },
       methods: {
           //next slide
           toNext() {
               this.$refs.swiperProduct.$swiper.slideNext();
           },
           //previous slide
           toPrevious() {
               this.$refs.swiperProduct.$swiper.slidePrev();
           },
           //change index in navigation
           changeNavigation() {
               this.index = (this.$refs.swiperProduct.$swiper.activeIndex) +1;
               if(this.index == 1){
                   document.getElementById('firstImage').style.display = 'block'; 
               }
               else{
                   document.getElementById('firstImage').style.display = 'none'; 
               }
           },
           toSlide(index) {
               if(!this.hasVideo) this.$refs.swiperProduct.$swiper.slideTo(index-1, 0)
               else this.$refs.swiperProduct.$swiper.slideTo(index, 0)
           },
           verifyImageSRC(id){
                if(this.imageLocation == 'condominiums'){
                    return id > 40
                }

                return id > 158
            }
       },
       computed:{
            youtubeVideoComputed(){
                let videoId = getIdFromURL(this.youtubeVideo)
                return videoId
            },
            hasVideo(){
                if(this.youtubeVideo == '')
                    return false

                if(this.youtubeVideo == null)
                    return false

                return true
            }
        }
   }
</script>