<template>
    <div>
        <div class="grid gap-1 grid-cols-3 lg:gap-4">
            <div :class="i === 0 ? 'col-span-3' : 'col-span-1'" class="overflow-hidden rounded-lg lg:rounded-xl" 
                :key="i" v-for="(item, i) in imagesSliced" >
                
                <img @click="show(item)" :key="reloadImg"
                class="lg:rounded-xl rounded-lg cursor-pointer object-center w-full h-full object-cover hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform" 
                :class="i == 0 && 'bigCover'"
                :src="$mediaFile(imageLocation, item.property_id , item.file)"
                :lazy-src="$mediaFile(imageLocation, item.property_id , item.file)" 
                >
                
                <WatermarkImage 
                  :imageSrc="$mediaFile(imageLocation, item.property_id , item.file)" 
                  @click="show(item)" :key="reloadImg"
                  v-if="false"
                  myclass="lg:rounded-xl rounded-lg cursor-pointer object-center w-full h-full object-cover hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform"
                />
            </div>
        </div>
        <!-- button -->
        <div class="flex items-center">
        <button @click="show()" class="hover:text-bronze mt-2 md:mt-6 py-1 text-sm font-bold uppercase leading-none tracking-button inset-x-0 mx-auto">
            <span class="mr-2">{{ $t('buttons.viewAll') }}</span>
        </button>
        </div>
    </div>
</template>
<style scoped>
.bigCover{
  height: calc( 30rem * 1131 / 1600 );
}
</style>
<script>
    export default {
        name: "GalleryBigImage",
        props: {
            numberImgs: Number,
            gallery: Array,
            imageLocation: String
        },
        data: function () {
            return {
                imagesSliced: this.gallery.slice(0, this.numberImgs),
                isModalVisible: false,
                reloadImg: 0
            }
        },
        created(){
            this.reloadImg+=1
        },
        methods: {
            show(item) {
                this.$emit('show',item);
            },
            verifyImageSRC(id){
                if(this.imageLocation == 'condominiums'){
                    return id > 40
                }

                return id > 158
            }
        },
        computed:{
            getSlug(){
                if(this.$route.params.slug == undefined)
                    return 1
            return this.$route.params.slug
            }
        }
    }
</script>

