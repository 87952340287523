<template>
    <div class="relative flex items-center justify-center transform transition duration-500 hover:scale-110" :class="'text-' + color">
        <a :href='"https://wa.me/"+phoneNumber' target="_blank">
            <!-- desktop version -->
            <inline-svg class="lg:block hidden fill-current animate-spin-slow" height="10rem" width="10rem" src="/svg/socials/roundText.svg" />
            <inline-svg class=" lg:block hidden absolute inset-0 m-auto fill-current transform" height="3rem" width="3rem" src="/svg/socials/whatsapp.svg" />
            <!-- mobile version -->
            <inline-svg class="lg:hidden block fill-current animate-spin-slow" height="10rem" width="5rem" src="/svg/socials/roundText.svg" />
            <inline-svg class="lg:hidden block absolute inset-0 m-auto fill-current transform" height="1.5rem" width="3rem" src="/svg/socials/whatsapp.svg" />
        </a>
    </div>
</template>

<script>
    export default {
        name: "WhatsappContact",
        props: {
            phoneNumber: String,
            color: String
        }
    }
</script>