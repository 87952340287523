<template>
    <transition name="modal-fade">
        <div class="modal-backdrop fixed inset-0 bg-black bg-opacity-50	flex justify-center items-center z-125">
            <div class="bg-white py-2 md:py-4 lg:py-8 rounded-lg w-full lg:w-2/6 h-auto overflow-y-auto mt-0 lg:h-auto lg:overflow-y-none">
                <header class="flex relative items-center justify-end px-2 md:px-4 lg:px-8">
                    <button @click="close">
                        <inline-svg class="transform origin-center rotate-45 w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="black"
                            src="/svg/plus.svg" />
                    </button>
                </header>
                <!-- begin:: modal boody -->
                <section class="modal-body mt-2 lg:mt-6">
                    <slot name="body">
                        This is the default body!
                    </slot>
                </section>
                <!-- end:: modal boody -->
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'BookVisitModal',
    methods: {
      close() {
        this.$emit('close');
      },
       show() {
        this.$emit('show');
      },
    },
  };
</script>