<template>
    <div class="w-full">
        <div class="ml-8 mr-8">
            <div >
                <AgentCard 
                    location="agents"
                    :id="null" 
                    :img="undefined"
                    :agentName="null"
                    :realEstateAdvisor="true"
                    :key="reloadAgent"
                />
            </div> 
            <div class="w-full flex justify-center">
                <form @submit.prevent="processForm" class="space-y-2 w-full lg:w-10/12 md:mt-4 mt-2 lg:mt-6" id="messageForm">
                    <div class="flex w-full space-x-3 md:space-x-8">
                        <iText class="lg:mt-4" id="firstName" name="firstName" :placeholder="$t('homepage.contact.inputs.firstName')"
                            ref="firstName" type="text" v-model="contact.firstName" />
                        <iText class="lg:mt-4" id="lastName" name="lastName" :placeholder="$t('homepage.contact.inputs.lastName')"
                            ref="lastName" type="text" v-model="contact.lastName" />
                    </div>

                    <div class="flex w-full space-x-3 md:space-x-8">
                        <iText class="lg:mt-4" id="email" name="email" :placeholder="$t('homepage.contact.inputs.email')" ref="email"
                            type="email" v-model="contact.email" />
                        <iText class="lg:mt-4" id="phone" name="phone" :placeholder="$t('homepage.contact.inputs.phone')" ref="phone" type="tel"
                            v-model="contact.phone" />
                    </div>

                    <iTextarea class="lg:mt-4" name="message" :placeholder="$t('homepage.contact.inputs.message')" ref="message"
                        v-model="contact.message" id="message" />

                    <div class="pb-2">
                        <div id="check1Div">
                            <iCheckbox class="h-auto lg:mt-8" id="check1" name="terms" :text="$t('homepage.contact.inputs.agree')"
                                :privacy="$t('homepage.contact.inputs.privacy')" />
                        </div>
                        <div id="check2Div">
                            <iCheckbox class="h-auto lg:mb-8" id="check2" name="terms" :text="$t('homepage.contact.inputs.communications')" />
                        </div>
                    </div>
                    <div class="pb-2" v-show="formError">
                        <p class="text-sm text-red-400">* {{ $t('General.forms.error') }}</p>
                    </div>
                    <button 
                        form="messageForm"
                        type="submit"
                        class="mt-8 bg-black hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-full py-2 md:py-3">
                        <span>{{ $t('buttons.sendMessage') }}</span>
                        <div class="absolute right-3 flex justify-center">
                            <inline-svg fill="white" height="15" src="/svg/arrow/arrowGradient.svg" />
                        </div>
                    </button>

                </form>
            </div>
            <div class="md:mt-4 mt-2">
                <div class="flex space-x-4 md:space-x-8 justify-center items-center mt-6">
                    <a href='https://wa.me/351966764147' class="transform transition duration-500 hover:scale-110"
                        target="_blank">
                        <inline-svg fill="black" class="md:h-9 md:w-9 w-6 h-6" src="/svg/socials/whatsapp.svg" />
                    </a>

                    <a href='tel:Zoom351966764147' class="transform transition duration-500 hover:scale-110"
                        target="_blank">
                        <inline-svg fill="black" class="md:h-9 md:w-9 w-6 h-6" src="/svg/socials/zoom.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import iText from '@/components/contact/types/iText';
import iTextarea from '@/components/contact/types/iTextarea';
import iCheckbox from '@/components/contact/types/iCheckbox';
import AgentCard from '@/components/agents/AgentCard';

// Execution code
import mixin from '@/models/general/GeneralModel'

    export default {
    name: "BookVisitForm",
    mixins: [mixin],
    components: {
            iText,
             iTextarea,
             iCheckbox,
             AgentCard
        },
    props: ['agent', 'house_id', 'block', 'reference'],
    data() {
        return {
            contact: {
                firstnName: "",
                lastName: "",
                email: "",
                phone: "",
                message: "",
                receive_comm: "",
                agent_id: 0,
                id: 0,
                block: '',
                reference: ''
            },
            reloadAgent: 0,
            visibleAgent: false,
            activeAgent: null,
            formError: false
        }
    },
    created(){
        if(this.agent != null){
            if(this.agent.length == 0)
                this.visibleAgent = false
            else
                this.visibleAgent = true
        }
    },
    computed: {
      id() {
        if(this.agent != null)
            return this.agent.id

        return -1
      },
      img(){
          if(this.agent != null)
            return this.agent.avatar

        return undefined
      },
      agentName(){
          return this.agent.first_name + ' ' + this.agent.last_name
      }
    },
    methods:{
        async processForm(){
            
            const name = document.getElementById('firstName').value;
            this.contact.firstnName = name
            this.contact.lastName = document.getElementById('lastName').value
            this.contact.email = document.getElementById('email').value
            this.contact.phone = document.getElementById('phone').value
            this.contact.message = document.getElementById('message').value
            this.contact.receive_comm = document.getElementById('check2').checked
            if(this.agent != null)
                this.contact.agent_id = this.agent.id
            this.contact.id = this.house_id
            this.contact.block = this.block
            this.contact.reference = this.reference
            
            if(this.validateForm())
                return

            await this.bookVisit(this.contact)
            this.$emit('thankYouMessage', name );

        },
        validateForm(){
            let invalid = false
            if(this.contact.firstnName == ""){
                document.getElementById('firstName').style="border-color: red;";
                invalid = true
            }

            if(this.contact.lastName == ""){
                document.getElementById('lastName').style="border-color: red;";
                invalid = true
            }

            if(this.contact.email == ""){
                document.getElementById('email').style="border-color: red;";
                invalid = true
            }

            if(this.contact.message == ""){
                document.getElementById('message').style="border-color: red;";
                invalid = true
            }

            if(!document.getElementById('check1').checked){
                document.getElementById('check1Div').style="color: red;";
                invalid = true
            }

            this.formError = invalid
            
            return invalid
        }
    }
}
</script>