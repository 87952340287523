<template>
    <div class="vue-share-it-wrapper">
        <a class="share-icon icon-border-round share-icon-email">
        <ShareNetwork
            network="email"
            :url="url"
            :title="title"
            :description="'\n'"
        >
            <div class="hidden sm:block">
                <svg data-v-3e74bce9 xmlns="http://www.w3.org/2000/svg" :width="Mywidth" :height="Myheight" fill="#BE5E3B" class="bi bi-envelope svg-inline--fa fa-w-14 fa-3x" viewBox="-3 -3 22 22">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                </svg>
            </div>
            <div class="sm:hidden">
                <svg data-v-3e74bce9 xmlns="http://www.w3.org/2000/svg" :width="Mywidth" :height="Myheight" fill="#BE5E3B" class="bi bi-envelope svg-inline--fa fa-w-7 fa-3x" viewBox="-3 -3 22 22">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                </svg>
            </div>
        </ShareNetwork>
        </a>
    </div>
</template>
<style scoped>
.share-icon-email{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
export default {
    name: 'ShareEmail',
    props:{
        url:{
            type: String,
            default: ''
        },
        title:{
            type: String,
            default: ''
        },
        description:{
            type: String,
            default: ''
        },
        Mywidth:{
            type: String,
            default: '3em'
        },
        Myheight:{
            type: String,
            default: '3em'
        },
    }
}
</script>
