<template>
    <div class="md:mt-4 mt-2 w-full" v-if="isAgent">
        <div class="flex sm:space-x-8 justify-center items-center mt-6 w-full">
            <div class="hidden sm:block w-1/4 flex justify-center">
                <img
                    class="rounded-lg w-32"
                    :src="getImg == undefined ? '/agent/profile_icon.jpg' : $mediaFile(getLocation, getId , getImg)">
            </div>
            <div class="w-full sm:w-1/2 mb-4 sm:mb-0">
                <p v-if="!realEstateAdvisor" class="font-blackMango md:w-2/3 lg:w-full inset-x-0 mx-auto lg:text-left tracking-wider font-bold lg:text-lg text-price">{{ agentName }}</p>
                <div v-else>
                    <p class="font-blackMango md:w-2/3 lg:w-full inset-x-0 mx-auto text-center tracking-wider font-bold lg:text-md text-md">{{ $t('RealEstateAdvisor') }}</p>
                    <p class="font-blackMango md:w-2/3 lg:w-full inset-x-0 mx-auto text-center tracking-wider font-bold lg:text-lg text-price">LA AGENCY</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AgentCard",
    props:{
        isAgent: {
            value: Boolean,
            default: true
        },
        location: String,
        id: Number,
        img: String,
        agentName: String,
        realEstateAdvisor:{
            type: Boolean,
            default: false
        }
    },
    created(){
        // !!!!!!!!!!!!!! NOTA IMPORTANTE !!!!!!!!!!!!
    },
    data(){
        return{
            emptyUserPath: 'empty_agent'
        }
    },
    computed:{
        getLocation(){
            return this.location
        },
        getId(){
            if(this.img == "")
                return 'empty_agent'

            return this.id
        },
        getImg(){
            if(this.img == "")
                return undefined

            return this.img
        }
    }
}
</script>